import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import useRequest from '../../api/Api';
import UnitService from '../../api/services/UnitService';
import CustomerService from '../../api/services/CustomerService';
import InstallerService from '../../api/services/InstallerService';

import { UnitForm } from '../../components/unit/UnitForm';
import ValidateUnitForm from '../../components/unit/ValidateUnitForm';
import { AddUnitForm } from '../../components/unit/AddUnitForm';
import { UnitSuccess } from '../../components/unit/UnitSuccess';
import { QrScanner } from '../../components/common/QrScanner';
import IdGenerator from '../../components/common/IdGenerator';
import QrGenerator from '../../components/common/QrGenerator';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import Search from '../../components/customer/Search';

export function ReplaceUnit() {
  const request = useRequest();
  const { user } = useAuth0();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileage': 0,
    'carMileageAtInstall': 0,
    'mileageTravelled': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const initialCustomer = {
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  const [unit, setUnit] = useState(initialUnit);
  const [oldUnitId, setOldUnitId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [unitExists, setUnitExists] = useState(true);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState(initialCustomer);
  const [customerModified, setCustomerModified] = useState(false);

  const next = () => setStep(step + 1);

  // Add unit
  const addUnit = async () => {
    if (user === null)
      return;

    // Get or Add installer
    let installer = await getInstaller();
    if (!installer)
      installer = await addInstaller();

    // Update or Add customer
    let customer = await updateCustomer();
    if (!customer)
      customer = await addCustomer();

    const newUnit = { ...unit, installerId: installer.id, customerId: customer.id };

    return await request(UnitService.create(newUnit));
  }

  // Disable unit
  const disableUnit = async () => {
    return await request(UnitService.disable(oldUnitId));
  }

  // Fetch installer
  const getInstaller = async () => {
    return await request(InstallerService.getByProviderId(user.sub));
  }

  // Add installer
  const addInstaller = async () => {
    return await request(InstallerService.create({ providerUserId: user.sub, name: user.nickname, email: user.email }));
  }

  // Update customer
  const updateCustomer = async () => {
    if (customer.id) {
      if (customerModified)
        return await request(CustomerService.update(customer.id, customer));

      return customer;
    }
  }

  // Add customer
  const addCustomer = async () => {
    return await request(CustomerService.create(customer));
  }

  // Handle forms/components

  const handleScan = (result) => {
    setUnit({ ...unit, id: result });
    if (step === 1)
      validateOldUnit(result);
    else if (step === 3)
      validateNewUnit(result);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });

    if (!isActive)
      setIsActive(!isActive);
    if (!unitExists)
      setUnitExists(!unitExists);
  }

  // Old unit
  // Check if unit exists
  const validateOldUnit = async (id) => {
    const validUnit = await request(UnitService.getById(id))
      .catch(() => setUnitExists(false));

    if (validUnit) {
      setUnit(validUnit);

      if (validUnit.customer)
        setCustomer(validUnit.customer);

      setIsActive(validUnit.active);
      if (validUnit.active)
        next();
    }
  }

  const handleSubmitOldUnit = (e) => {
    e.preventDefault();
    setOldUnitId(unit.id);
    setUnit({ ...unit, id: '', carMileageAtInstall: unit.carMileage });
    // console.log(unit);
    next();
  }


  // New unit

  const validateNewUnit = async (id) => {
    const validatedUnit = await request(UnitService.validate(id));
    validatedUnit
      ? setUnitExists(validatedUnit)
      : next();
  }

  const handleAddUnitSubmit = async (e) => {
    e.preventDefault();

    // Disable unit
    const unitDisabled = await disableUnit();
    if (unitDisabled) {
      console.log(`Disabled ${unitDisabled.id}`);
      const newUnit = await addUnit();
      if (newUnit)
        next();
    }
  }

  const handleIdGen = (id) => {
    setUnit({ ...unit, id });
    validateNewUnit(id);
  }

  // Customer
  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });

    if (!customerModified)
      setCustomerModified(true);
  }

  // Searchbar
  const handleSearchOptions = async (inputValue) => {
    return request(CustomerService.getByName(inputValue)).then(c => c.items);
  };

  return (
    <div>
      <h2>Byt enhet</h2>
      {step === 1 && (
        <div>
          <h4>Nuvarande enhet</h4>
          <p>Skanna QR-koden eller fyll i id för enheten som ska bytas ut</p>
          <QrScanner onScan={handleScan} />
          <ValidateUnitForm className="mt-4" unit={unit} onChange={handleChange} onValidate={validateOldUnit} invalid={!isActive || !unitExists} feedback={!isActive && 'Enheten är släckt' || !unitExists && 'Enheten finns ej'} />
          {/* <ValidateUnitForm unit={unit} onChange={handleChangeUnit} onSubmit={validateOldUnit} invalid={isInactive} feedback={isInactive && 'Enheten är inaktiv'} /> */}
        </div>
      )}
      {step === 2 && (
        <div>
          <p>Uppdatera enhetens och fordonets uppgifter.</p>
          <UnitForm unit={unit} onChange={handleChange} onSubmit={handleSubmitOldUnit} buttonColor="primary">
            <hr className="mt-5 mb-4" />
            <h5>Kunduppgifter</h5>
            <p className="mb-4">Uppdatera kunduppgifterna eller välj en annan kund genom sökfältet.</p>
            <Search
              initialValue={[customer, initialCustomer]}
              selectedValue={customer}
              onSelect={(c) => setCustomer(c)}
              loadOptions={handleSearchOptions}
            />
            <CustomerFormFields customer={customer} onChange={handleCustomerChange} />
          </UnitForm>
        </div>
      )}
      {step === 3 && (
        <div>
          <h4>Ny enhet</h4>
          <p>Skanna QR-koden eller fyll i id för den nya enheten</p>
          <QrScanner onScan={handleScan} />
          <div className="my-3">
            <IdGenerator onGenerate={handleIdGen} />
          </div>
          <ValidateUnitForm unit={unit} onChange={handleChange} onValidate={validateNewUnit} invalid={unitExists} feedback={unitExists && 'Enheten finns redan'} />
        </div>
      )}
      {step === 4 && (
        <div>
          <p>Fyll i uppgifter om fordonet till den nya enheten.</p>
          <AddUnitForm unit={unit} onChange={handleChange} onSubmit={handleAddUnitSubmit} />
        </div>
      )}
      {step === 5 && (
        <div>
          <UnitSuccess />
          <QrGenerator value={unit.id} />
        </div>
      )}
    </div>
  );
}
