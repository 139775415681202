import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import { RegNumberInput } from './RegNumberInput';
import Search from '../../components/customer/Search';

const UpdateUnitForm = ({ unitToUpdate, onUpdate, onSearch }) => {
  const initialUnit = {
    carRegNo: unitToUpdate?.carRegNo || '',
    carMileage: unitToUpdate?.carMileage || 0,
    carMileageAtInstall: unitToUpdate?.carMileageAtInstall || 0,
    mileagePerYear: unitToUpdate?.mileagePerYear || 0,
    active: unitToUpdate.active,
    customer: unitToUpdate?.customer || null,
  }
  const initialCustomer = {
    name: '',
    email: '',
    phone: '',
    address: '',
    zip: '',
    city: ''
  };

  const [unit, setUnit] = useState(initialUnit);

  const onSubmit = (e) => {
    e.preventDefault();
    onUpdate(unit);
  }

  const onUnitChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });
  }

  const onCustomerChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, customer: { ...unit.customer, [name]: value } });
  }

  return (
    <Form onSubmit={onSubmit}>
      <RegNumberInput value={unit.carRegNo} onChange={onUnitChange} />
      <FormGroup>
        <Label htmlFor="carMileage">Miltal fordon</Label>
        <Input type="number" name="carMileage" id="carMileage" onChange={onUnitChange} value={unit.carMileage} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="carMileageAtInstall">Miltal fordon vid installation</Label>
        <Input type="number" name="carMileageAtInstall" id="carMileageAtInstall" onChange={onUnitChange} value={unit.carMileageAtInstall} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="mileagePerYear">Årlig körsträcka</Label>
        <Input type="number" name="mileagePerYear" id="mileagePerYear" onChange={onUnitChange} value={unit.mileagePerYear} required />
      </FormGroup>
      <hr />
      <h5>Kunduppgifter</h5>
      <Search
        initialValue={[unit.customer, initialCustomer]}
        selectedValue={unit.customer}
        onSelect={(customer) => setUnit({ ...unit, customer: customer })}
        loadOptions={onSearch}
      />
      {unit.customer && (
        <CustomerFormFields customer={unit.customer} onChange={onCustomerChange} />
      )}
      <Button color="primary">Spara</Button>
    </Form>
  );
}

export default UpdateUnitForm;