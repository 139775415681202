import React, { useState } from 'react';

import useRequest from '../../api/Api';
import CustomerService from '../../api/services/CustomerService';
import UnitService from '../../api/services/UnitService';
import InstallerService from '../../api/services/InstallerService';

import { QrScanner } from '../../components/common/QrScanner';
import ValidateUnitForm from '../../components/unit/ValidateUnitForm';
import { AddUnitForm } from '../../components/unit/AddUnitForm';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import { UnitSuccess } from '../../components/unit/UnitSuccess';
import IdGenerator from '../../components/common/IdGenerator';
import QrGenerator from '../../components/common/QrGenerator';
import Search from '../../components/customer/Search';
import { useAuth0 } from '@auth0/auth0-react';

export function AddUnit() {
  const { user } = useAuth0();
  const request = useRequest();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileageAtInstall': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const initialCustomer = {
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  const [unit, setUnit] = useState(initialUnit);
  const [unitExists, setUnitExists] = useState(false);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState(initialCustomer);
  const [customerModified, setCustomerModified] = useState(false);

  const next = () => setStep(step + 1);

  // Add unit
  const addUnit = async () => {
    if (user === null)
      return;

    // Get or Add installer 
    let installer = await getInstaller();
    if (!installer)
      installer = await addInstaller();

    // Update or Add customer
    let customer = await updateCustomer();
    if (!customer)
      customer = await addCustomer();

    const newUnit = { ...unit, installerId: installer.id, customerId: customer.id };

    return await request(UnitService.create(newUnit));
  }

  // Fetch installer
  const getInstaller = async () => {
    return await request(InstallerService.getByProviderId(user.sub));
  }

  // Add installer
  const addInstaller = async () => {
    return await request(InstallerService.create({ providerUserId: user.sub, name: user.nickname, email: user.email }));
  }

  // Update customer
  const updateCustomer = async () => {
    if (customer.id) {
      if (customerModified)
        return await request(CustomerService.update(customer.id, customer));

      return customer;
    }
  }

  // Add customer
  const addCustomer = async () => {
    return await request(CustomerService.create(customer));
  }

  // Check if unit exists, if not proceed to the next step
  const validateUnit = async (id) => {
    const validatedUnit = await request(UnitService.validate(id));
    validatedUnit
      ? setUnitExists(validatedUnit)
      : next();
  }

  // Handle forms/components

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });
    if (unitExists)
      setUnitExists(!unitExists);
  }

  const handleScan = (result) => {
    setUnit({ ...unit, id: result })
    validateUnit(result);
  }

  const handleAddUnitSubmit = async (e) => {
    e.preventDefault();

    const newUnit = await addUnit();
    if (newUnit)
      next();
  }

  const handleIdGen = (id) => {
    setUnit({ ...unit, id: id });
    validateUnit(id);
  }

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });

    if (!customerModified)
      setCustomerModified(true);
  }

  // Searchbar
  const handleSearchOptions = async (inputValue) => {
    return request(CustomerService.getByName(inputValue)).then(c => c.items);
  };

  return (
    <div>
      <h2>Registrera enhet</h2>
      {step === 1 && (
        <div>
          <p>Skanna QR-koden eller fyll i id för enheten som ska registreras</p>
          <QrScanner onScan={handleScan} />
          <div className="my-3">
            <IdGenerator onGenerate={handleIdGen} />
          </div>
          <ValidateUnitForm unit={unit} onChange={handleChange} onValidate={validateUnit} invalid={unitExists} feedback={unitExists && 'Enheten finns redan'} />
        </div>
      )}
      {step === 2 && (
        <div>
          <p>Fyll i uppgifter om fordonet.</p>
          <AddUnitForm unit={unit} onChange={handleChange} onSubmit={handleAddUnitSubmit}>
            <hr className="mt-5 mb-4" />
            <h5>Kunduppgifter</h5>
            <p className="mb-4">Fyll i kunduppgifterna eller sök efter en kund.</p>
            <Search
              initialValue={initialCustomer}
              selectedValue={customer}
              onSelect={(c) => setCustomer(c)}
              loadOptions={handleSearchOptions}
              className="mb-3"
            />
            <CustomerFormFields customer={customer} onChange={handleCustomerChange} />
          </AddUnitForm>
        </div>
      )}
      {step === 3 && (
        <div>
          <UnitSuccess />
          <QrGenerator value={unit.id} />
        </div>
      )}
    </div>
  )
}