import React, { useState } from "react"
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const regex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/);
const regexInput = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

const ValidateUnitForm = ({ className, unit, onChange, onValidate, invalid, feedback }) => {
  const [isBadFormat, setIsBadFormat] = useState(false);

  const onChangeRegex = (e) => {
    const val = e.target.value;
    onChange(e);
    if (regex.test(val)) {
      setIsBadFormat(false);
    }
    else {
      setIsBadFormat(true);
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    onValidate(unit.id);
  }

  return (
    <div className={className}>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label htmlFor="id">Id</Label>
          <Input type="text" name="id" id="id" onChange={onChangeRegex} value={unit.id} pattern={regexInput} invalid={(isBadFormat || invalid) && !(unit.id === '')} /*valid={!isBadFormat && !(unit.id === '')} */ required />
          <FormFeedback>{feedback ? feedback : (<p>Fel format, UUID/GUID, exempel - 11edc52b-2918-4d71-9058-f7285e29d894</p>)}</FormFeedback>
        </FormGroup>
        <Button color="primary">Nästa</Button>
      </Form>
    </div>
  );
}

export default ValidateUnitForm;