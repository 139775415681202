import React, { useEffect, useState } from 'react';
import './NavMenu.scss';
import { Collapse, Container, Navbar, Nav, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './auth/LoginButton';
import LogoutButton from './auth/LogoutButton';
import config from '../config';

const roleClaimType = config.AUTH_ROLES;

export function NavMenu() {
  const { user } = useAuth0();

  const [collapsed, setCollapsed] = useState(true);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const claims = await getIdTokenClaims();
      setRoles(claims[roleClaimType] || []);
    }

    if (isAuthenticated)
      getRoles();
  }, [isAuthenticated, getIdTokenClaims]);


  const checkRoles = (role) => {
    // const roles = await getRoles();
    const isAuthorized = roles.some(r => role.includes(r))
    // const isAuthorized = roles.includes(role);
    return isAuthorized;
  }

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  return (
    <header>
      <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom mb-3" light>
        <Container>
          <NavbarBrand tag={Link} to="/">Emi<span className="font-weight-bold">Nique</span></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!collapsed} navbar>
            <Nav navbar className="flex-grow">
              {isAuthenticated && (
                <>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/">Dashboard</NavLink>
                  </NavItem>
                  {checkRoles(["admin", "installer"]) && (
                    <>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/units">Monterade enheter</NavLink>
                      </NavItem>
                    </>
                  )}
                  {checkRoles("admin") && (
                    <>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/installers">Montörer</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/customers">Kunder</NavLink>
                      </NavItem>
                    </>
                  )}
                  {checkRoles("installer") && (
                    <>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/add">Ny enhet</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/replace">Byta enhet</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/disable">Släck enhet</NavLink>
                      </NavItem>
                    </>
                  )}
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav className="flex flex--center">
                      <span className="avatar">
                        <img src={user.picture} alt={user.name} />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem header>{user.name} ({user.email})</DropdownItem>
                      <DropdownItem header>{roles.join(", ")}</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <LogoutButton />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
              {!isAuthenticated && (
                <NavItem>
                  <LoginButton />
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </header >
  );
}
