import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';
// import jwt_decode from 'jwt-decode';

const baseURL = config.API_URL;

async function getAuthHeaderValue(auth) {
  // If user is logged in, get the token from the login provider.
  if (auth.isAuthenticated) {
    return `Bearer ${await auth.getAccessTokenSilently(/*{ audience: baseURL }*/)}`;
  }
  return 'Anonymous';
}

function useRequest() {
  const auth = useAuth0();

  const request = async (request) => {
    const accessToken = await getAuthHeaderValue(auth);
    // var decoded = jwt_decode(accessToken);
    // console.log(decoded);

    const { data } = await axios({
      baseURL,
      ...request,
      headers: {
        ...request.headers,
        // Add the Authorization header to the existing headers
        Authorization: accessToken //await getAuthHeaderValue(auth),
      },
    }).catch((error) => {
      // In case of request failure, extract error from response body
      if (error.response) {
        // Response has been received from the server
        const message = error.response.data.message;
        throw new Error(message || 'Unknown error occured. We are looking into this.');
      } else {
        // No response from server, should be a network issue
        throw new Error('Are you offline? Check your internet connection and try again.');
      }
    });

    return data;
  };

  return request;
};

export default useRequest;
