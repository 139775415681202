import React, { useState } from 'react'
import { UnitForm } from '../../components/unit/UnitForm';
import ValidateUnitForm from '../../components/unit/ValidateUnitForm';
import { QrScanner } from '../../components/common/QrScanner';
import UnitService from '../../api/services/UnitService';
import useRequest from '../../api/Api';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';

export default function DisableUnit() {
  const request = useRequest();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileage': 0,
    'mileageTravelled': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const [unit, setUnit] = useState(initialUnit);
  const [isActive, setIsActive] = useState(true);
  const [unitExists, setUnitExists] = useState(true);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState({ name: '', email: '', phone: '', address: '' });

  const next = () => setStep(step + 1);


  // Disable unit
  const disableUnit = async () => {
    return await request(UnitService.disable(unit.id));
  }

  const handleScan = (result) => {
    setUnit({ ...unit, id: result });
    validateUnit(result);
  }

  const validateUnit = async (id) => {
    const validUnit = await request(UnitService.getById(id))
      .catch(() => setUnitExists(false));

    if (validUnit) {
      setUnit(validUnit);

      if (validUnit.customer)
        setCustomer(validUnit.customer);

      setIsActive(validUnit.active);
      if (validUnit.active)
        next();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });

    if (!isActive)
      setIsActive(!isActive);
    if (!unitExists)
      setUnitExists(!unitExists);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const unitDisabled = await disableUnit();
    if (unitDisabled) {
      console.log(`Disabled ${unitDisabled.id}`);
      next();
    }
  }

  return (
    <div>
      <h2>Släck enhet</h2>
      {step === 1 && (
        <div>
          <p>Skanna QR-koden eller fyll i id för enheten som ska släckas</p>
          <QrScanner onScan={handleScan} />
          <ValidateUnitForm className="mt-4" unit={unit} onChange={handleChange} onValidate={validateUnit} invalid={!isActive || !unitExists} feedback={!isActive && 'Enheten är redan släckt' || !unitExists && 'Enheten finns ej'} />
        </div>
      )}
      {step === 2 && (
        <div>
          <p>Kontrollera att uppgifterna stämmer.</p>
          <UnitForm unit={unit} onChange={handleChange} onSubmit={handleSubmit} buttonContent='Släck enhet' buttonColor='danger' disableAll={true} >
            <hr />
            <h5>Kunduppgifter</h5>
            <CustomerFormFields customer={customer} disableAll={true} />
          </UnitForm>
        </div>
      )}
      {step === 3 && (
        <div>
          <p>Enheten är nu släckt</p>
        </div>
      )}
    </div>
  );
}
