
import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { RegNumberInput } from './RegNumberInput';

export function UnitForm({ unit, onChange, onSubmit, buttonContent, buttonColor, disableAll, children }) {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="id">Id</Label>
        <Input type="text" name="id" id="id" onChange={onChange} value={unit.id} disabled />
      </FormGroup>
      <RegNumberInput value={unit.carRegNo} onChange={onChange} disabled={disableAll} />
      <FormGroup>
        <Label htmlFor="carMileage">Miltal fordon</Label>
        <Input type="number" name="carMileage" id="carMileage" onChange={onChange} value={unit.carMileage} disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="mileageTravelled">Miltal enhet</Label>
        <Input type="number" name="mileageTravelled" id="mileageTravelled" onChange={onChange} value={unit.mileageTravelled} disabled />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="mileagePerYear">Årlig körsträcka</Label>
        <Input type="number" name="mileagePerYear" id="mileagePerYear" onChange={onChange} value={unit.mileagePerYear} disabled={disableAll} />
      </FormGroup>
      {children}
      <Button color={buttonColor}>{buttonContent || 'Nästa'}</Button>
    </Form>
  );
}