import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import useRequest from '../../api/Api';
import UnitService from '../../api/services/UnitService';
import InstallerService from '../../api/services/InstallerService';
import Pagination from 'react-responsive-pagination';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
import { useHistory } from 'react-router-dom';

export function ListUnits() {
  const request = useRequest();
  const history = useHistory();

  const [tableHeaders, setTableHeaders] = useState([
    { id: 'id', value: '#', direction: 'none' },
    { id: 'installDate', value: 'Installations datum', direction: 'none' },
    { id: 'modifiedDate', value: 'Senast ändrad', direction: 'none' },
    { id: 'installer.name', value: 'Installatör', direction: 'none' },
    { id: 'customer.name', value: 'Kund', direction: 'none' },
    { id: 'carRegNo', value: 'Reg.nummer', direction: 'none' },
    { id: 'mileageTravelled', value: 'Mil sedan inst.', direction: 'none' },
    { id: 'mileagePerYear', value: 'Ber. körsträcka/år', direction: 'none' },
    { id: 'active', value: 'Aktiv', direction: 'none' },
    { id: 'edit', value: '', direction: '' },
  ]);

  const [units, setUnits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const getUnits = async () => {
      setLoading(true);

      await syncInstallers();
      const sortingQuery = sortBy.length > 0 ? sortBy.reduce((result, { name, direction }) => [...result, ...direction === 'asc' ? [`${name} ${direction}`] : [], ...direction === 'desc' ? [`${name} ${direction}`] : []], []).join(',') : ''; //e.direction === 'asc' || e.direction === 'desc') //.reduce().join(',');
      const units = await fetchUnits(currentPage, sortingQuery)


      setUnits(units?.items);
      setCurrentPage(units?.currentPage);
      setTotalPages(units?.totalPages);

      setLoading(false);
    }

    getUnits();

  }, [sortBy, currentPage]);

  const fetchUnits = async (pageNumber, orderBy) => {
    const data = await request(UnitService.getList(pageNumber, undefined, orderBy));
    if (data)
      return data;
  }

  const syncInstallers = async () => {
    await request(InstallerService.sync());
  }

  // Pagination page click
  const pageClick = (page) => {
    if (!loading)
      setCurrentPage(page);
  }

  // Sort table by column
  const sortUnits = async (column) => {
    const updColumn = sortBy.some(e => e.name === column)
    if (updColumn) {
      const sortingList = sortBy.map(
        (c) => c.name === column
          ? {
            ...c, direction: (c.direction === 'asc' ? 'desc' : c.direction === 'desc' ? 'none' : 'asc')
          } : c
      ).filter(c => c.direction !== 'none');
      setSortBy(sortingList);

      const col = sortingList.find(c => c.name === column);
      setTableHeaders(tableHeaders.map(h => h.id === column ? { ...h, direction: col?.direction ?? 'none' } : h));
    }
    else {
      setSortBy([...sortBy, { name: column, direction: 'asc' }]);
      setTableHeaders(tableHeaders.map(h => h.id === column ? { ...h, direction: 'asc' } : h));
    }
  }

  return (
    <div>
      <h2>Monterade enheter</h2>
      <Table responsive striped>
        <thead>
          <tr>
            {tableHeaders && tableHeaders.map(header => (
              header.value
                ? <th key={header.id} id={header.id} style={{ cursor: 'pointer' }} onClick={(e) => sortUnits(e.currentTarget.id)}>
                  {header.value}
                  <span>
                    {header.direction === 'asc' ? ' 🔽'
                      : header.direction === 'desc' ? ' 🔼'
                        : ''
                    }
                  </span>
                </th>
                : <th key={header.id} id={header.id} ></th>
            ))}
          </tr>
        </thead>
        <tbody>
          {units && units.map(unit => (
            <tr key={unit.id}>
              <th>{unit.id}</th>
              {/* format(date, "MMMM do, yyyy H:mma"); */}
              {/* 'd MMM yyyy HH:mm' */}
              <td>{format(new Date(unit.installDate), 'yyyy-MM-dd HH:mm', { locale: sv })}</td>
              <td>{format(new Date(unit.modifiedDate), 'yyyy-MM-dd HH:mm', { locale: sv })}</td>
              <td>{unit.installer?.name}</td>
              <td>{unit.customer?.name}</td>
              <td>{unit.carRegNo}</td>
              <td>{unit.mileageTravelled} mil</td>
              <td>{unit.mileagePerYear} mil</td>
              <td>{unit.active ? 'Aktiv' : 'Inaktiv'}</td>
              <td>
                <Button outline color="primary" size="sm" style={{ marginTop: '-0.3rem' }} onClick={() =>
                  history.push({ pathname: `/edit/${unit.id}` })}>Ändra</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
      <div>
        <Pagination
          current={currentPage}
          total={totalPages}
          onPageChange={pageClick}
        />
      </div>
    </div >
  );
}
